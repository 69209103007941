import { NgModule } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import {
  XpoLtlAuthFirestoreModule,
  XPO_LTL_AUTH_FIRESTORE_CONFIG,
  XpoLtlAuthFirestoreConfig,
} from '@xpo-ltl/ngx-ltl-auth/firestore';
import { environment } from '../../environments/environment';

export function authConfigFactory(configManagerService: ConfigManagerService): XpoLtlAuthFirestoreConfig {
  return new XpoLtlAuthFirestoreConfig(
    {
      appCode: configManagerService.getSetting('authAppCode'),
      scopes: configManagerService.getSetting('authScopes'),
      isProd: configManagerService.getSetting('authIsProd'),
      applicationRootUri: configManagerService.getSetting('authAppRootUri'),
    },
    {
      appName: configManagerService.getSetting('appName'),
      apiUri: configManagerService.getSetting('apiUrl'),
      skipRequest: (request) => {
        return (
          request.url.includes(configManagerService.getSetting('ltlHrcApiUri')) ||
          request.url.includes(configManagerService.getSetting('ltlCustomerNotificationApiUri'))
        );
      },
    },
    {
      firebaseOptions: environment.firebase,
      region: configManagerService.getSetting('region'),
    }
  );
}
@NgModule({
  imports: [
    XpoLtlAuthFirestoreModule.forRoot({
      provide: XPO_LTL_AUTH_FIRESTORE_CONFIG,
      useFactory: authConfigFactory,
      deps: [ConfigManagerService],
    }),
  ],
})
export class InitAuthModule {}

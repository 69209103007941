import { NgModule } from '@angular/core';
import {
  ConfigManagerModule,
  ConfigManagerService,
  CONFIG_MANAGER_SERVICE_CONFIGURATION,
} from '@xpo-ltl/config-manager';
import { BASE_PATH, CUST_BASE_PATH } from '../api';

export function basePathFactory(configManagerService: ConfigManagerService): string {
  return configManagerService.getSetting('ltlCustomerNotificationApiUri') + 'customer-notification/backend';
}

export function custBasePathFactory(configManagerService: ConfigManagerService): string {
  return configManagerService.getSetting('ltlCustomerNotificationApiUri') + 'customer-notification/backend';
}

@NgModule({
  imports: [ConfigManagerModule],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './../../assets/config.json' },
    },
    {
      provide: BASE_PATH,
      useFactory: basePathFactory,
      deps: [ConfigManagerService],
    },
    {
      provide: CUST_BASE_PATH,
      useFactory: custBasePathFactory,
      deps: [ConfigManagerService],
    },
  ],
})
export class InitConfigModule {}
